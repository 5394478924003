<template>
  <div class="merchant-login" v-loading="requestPending">
    <el-form
      :model="loginForm"
      status-icon
      :rules="rules"
      ref="loginForm"
      label-width="100px"
    >
      <el-form-item label="Login" prop="login">
        <el-input type="text" v-model="loginForm.login"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="merchant-login__submit"
          type="primary"
          @click="submitForm('loginForm')"
          >Login</el-button
        >
        <el-alert
          v-if="invalidLogin"
          title="Invalid credentials"
          type="error"
          show-icon
          @close="invalidLogin = false"
        >
        </el-alert>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import auth from '@/utils/auth'
  import { router } from '@/main.js'

  export default {
    name: 'merchant-login',

    data() {
      return {
        loginForm: {
          login: '',
          password: '',
        },
        rules: {
          login: [
            {
              required: true,
              message: 'Please type your login',
              trigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: 'Please type your password',
              trigger: 'blur',
            },
          ],
        },
        invalidLogin: false,
        requestPending: false,
      }
    },

    methods: {
      submitForm(formName) {
        const loginForm = this.$refs[formName]
        console.log(loginForm)

        loginForm.validate(async (valid) => {
          if (valid) {
            this.requestPending = true

            const resp = await auth.authorizeUser(
              loginForm.model.login,
              loginForm.model.password
            )

            if (resp.ok) {
              router.push('/merchantadminpanel-active-orders')
            } else {
              this.invalidLogin = true
            }

            this.requestPending = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .merchant-login {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30vh;

    &__submit {
      width: 100%;
    }
  }
</style>
